/* Custom General jQuery
/*--------------------------------------------------------------------------------------------------------------------------------------*/
;(function($, window, document, undefined) {
	//Genaral Global variables
	//"use strict";
	var $win = $(window);
	var $doc = $(document);
	var $winW = function(){ return $(window).width(); };
	var $winH = function(){ return $(window).height(); };
	var $screensize = function(element){  
			$(element).width($winW()).height($winH());
		};
		
		var screencheck = function(mediasize){
			if (typeof window.matchMedia !== "undefined"){
				var screensize = window.matchMedia("(max-width:"+ mediasize+"px)");
				if( screensize.matches ) {
					return true;
				}else {
					return false;
				}
			} else { // for IE9 and lower browser
				if( $winW() <=  mediasize ) {
					return true;
				}else {
					return false;
				}
			}
		};

	$doc.ready(function() {
/*--------------------------------------------------------------------------------------------------------------------------------------*/		
		// Remove No-js Class
		$("html").removeClass('no-js').addClass('js');
		
		
		// IOS device detect
		var deviceAgent = navigator.userAgent.toLowerCase();
		var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
		if (agentID || navigator.platform.toUpperCase().indexOf('MAC')>=0) {
			$('body').addClass('ios-device');
		}
		
		
		/* Menu ICon Append prepend for responsive
		---------------------------------------------------------------------*/
		$(window).on('resize', function(){  
			if (screencheck(767)) {
				if(!$('#menu').length){
					$('#mainmenu').prepend('<a href="#" id="menu" class="menulines-button"><span class="menulines"></span> <em>Menu</em></a>');
				}
			} else {
				$("#menu").remove();
			}
		}).resize();
		
		
		/*Mobile menu click
		---------------------------------------------------------------------*/
		$(document).on('click',".navbar-toggle", function(){
			$(this).toggleClass('collapsed');
			$('body').toggleClass('nav-show');
			return false;
		});
		
		
		/*Mobile footer phone no menu click
		---------------------------------------------------------------------*/
		$(window).on('resize', function(){
			if (screencheck(767)) {
				$(".ftr-tel").attr("href", "tel:+43-512-284338");
			}else {
				$(".ftr-tel").attr("href", "#");
			}
		}).resize();
		
		
		/*Word latter slider
		---------------------------------------------------------------------*/
	  	var animationDelay = 35000,
			lettersDelay = 50;

	  	
		
		/* Popup function
		---------------------------------------------------------------------*/
		let videoSrc = $('.video-iframe').attr('src');
		var $dialogTrigger = $('.poptrigger'),
		$pagebody =  $('body');
		$dialogTrigger.click( function(){
			var popID = $(this).attr('data-rel');
			$('body').addClass('overflowhidden');
			var winHeight = $(window).height();
			$('#' + popID).fadeIn();
			var popheight = $('#' + popID).find('.popup-block').outerHeight(true);
			
			if( $('.popup-block').length){
				var popMargTop = popheight / 2;
				//var popMargLeft = ($('#' + popID).find('.popup-block').width()/2);
				
				if ( winHeight > popheight ) {
					$('#' + popID).find('.popup-block').css({
						'margin-top' : -popMargTop,
						//'margin-left' : -popMargLeft
					});	
				} else {
					$('#' + popID).find('.popup-block').css({
						'top' : 0,
						//'margin-left' : -popMargLeft
					});
				}
				
			}
			
			$('#' + popID).append("<div class='modal-backdrop'></div>");
			$('.popouterbox .modal-backdrop').fadeTo("slow", 0.60);
			if( popheight > winHeight ){
				$('.popouterbox .modal-backdrop').height(popheight);
			} 
			$('#' + popID).focus();
			

			if($(".video-iframe").length){
				let vidUrl = new URL(videoSrc)
				if(vidUrl.host === "www.youtube.com"){
					$('.video-iframe').attr('src', videoSrc + '&autoplay=1');
				}else{
					$('.video-iframe').attr('src', videoSrc + '?autoplay=1');
				}
			}

			return false;
		});
		
		$(window).on("resize", function () {
			if( $('.popouterbox').length && $('.popouterbox').is(':visible')){
				var popheighton = $('.popouterbox .popup-block').height()+60;
				var winHeight = $(window).height();
				if( popheighton > winHeight ){
					$('.popouterbox .modal-backdrop').height(popheighton);
					$('.popouterbox .popup-block').removeAttr('style').addClass('taller');
					
				} else {
					$('.popouterbox .modal-backdrop').height('100%');
					$('.popouterbox .popup-block').removeClass('taller');
					$('.popouterbox .popup-block').css({
						'margin-top' : -(popheighton/2)
					});
				}	
			}
		});
		
		//Close popup		
		$(document).on('click', '.close-dialogbox, .popouterbox .modal-backdrop', function(){
			if($(".video-iframe").length){
				$('.video-iframe').attr('src', videoSrc + '?autoplay=0');
			}
			
			$(this).parents('.popouterbox').fadeOut(300, function(){
				$(this).find('.modal-backdrop').fadeOut(250, function(){
					$('body').removeClass('overflowhidden');
					$('.popouterbox .popup-block').removeAttr('style');
					$(this).remove();
				});
			});
			return false;
		});

		/* Swiper slider
		---------------------------------------------------------------------*/
		if($('.project-slider').length) {
			var autoplay = 50;
			var time = 1;
			var $bar,
				isPause,
				tick,
				percentTime;
			var projectswiper = new Swiper('.project-slider', {
				direction: 'horizontal',
				spaceBetween: 0,
				loop:true,
				//watchSlidesProgress: true,
				dynamicBullets: true,
				//watchOverflow: false,
				//parallax: false,
				speed: 300,
				slidesPerView: 1,
				/*autoplay: {
					delay: autoplay,
					disableOnInteraction: false,
				},*/
				allowTouchMove: true,
				pagination: {
					el: '.project-sld-nav',
					clickable: true,
					renderBullet: function (index, className) {
					  return '<span class="' + className + '">' + (index + 1) + '</span>';
					},
				},
				on: {
					init: function () {
					},
					transitionStart: function () {
						startProgressbar();
					}
				},
			});

			var proTitleSlider = new Swiper('.project-title-slider', {
				spaceBetween: 0,
				speed: 500,
				loop: true,
				effect: 'fade',
				slidesPerView: 1,
				allowTouchMove: true,
			});

			projectswiper.controller.control = proTitleSlider;
			proTitleSlider.controller.control = projectswiper;

			$bar = $('.featured-projects-box .slide-timer span');
			function startProgressbar() {
				resetProgressbar();
				percentTime = 0;
				isPause = false;
				tick = setInterval(interval, 120);
			}
			function interval() {
				if (isPause === false) {
					percentTime += 1 / (time + 0.1);
					$bar.css({
						width: percentTime + "%"
					});
					if (percentTime >= 100) {
						//$slick.slick('slickNext');
						projectswiper.slideNext();
						startProgressbar();
					}
				}
			}
			function resetProgressbar() {
				$('.featured-projects-box .slide-timer span').css({
					width: 0 + '%'
				});
				clearTimeout(tick);
			}
		}
		
		/* Culture slider
		===============================*/
		if($('.culture-slider').length) {
			var culautoplay = 50;
			var cultime = 1;
			var $culbar,
				culisPause,
				cultick,
				culpercentTime;
			var cultureswiper = new Swiper('.culture-slider', {
				direction: 'horizontal',
				spaceBetween: 0,
				loop:true,
				//watchSlidesProgress: true,
				dynamicBullets: true,
				//watchOverflow: true,
				//parallax: true,
				speed: 300,
				slidesPerView: 1,
				allowTouchMove: true,
				pagination: {
					el: '.culture-sld-nav',
					clickable: true,
					renderBullet: function (index, className) {
					  return '<span class="' + className + '">' + (index + 1) + '</span>';
					},
				},
				on: {
					init: function () {
						//animationStart();
					},
					transitionStart: function () {
						//animationStart();
						culstartProgressbar();
					},
					progress: function () {
						//move();
					},	
				},
			});

			var cultureTitleSlider = new Swiper('.culture-title-slider', {
				spaceBetween: 0,
				speed: 500,
				loop: true,
				slidesPerView: 1,
				effect: 'fade',
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				allowTouchMove: true,
			});
			cultureswiper.controller.control = cultureTitleSlider;
			cultureTitleSlider.controller.control = cultureswiper;

			$culbar = $('.building-culture-list .slide-timer span');
			function culstartProgressbar() {
				culresetProgressbar();
				culpercentTime = 0;
				culisPause = false;
				cultick = setInterval(culinterval, 50);
			}
			function culinterval() {
				if (culisPause === false) {
					culpercentTime += 1 / (cultime + 0.1);
					$culbar.css({
						width: culpercentTime + "%"
					});
					if (culpercentTime >= 100) {
						//$slick.slick('slickNext');
						cultureswiper.slideNext();
						culstartProgressbar();
					}
				}
			}
			function culresetProgressbar() {
				$('.building-culture-list .slide-timer span').css({
					width: 0 + '%'
				});
				clearTimeout(cultick);
			}
		
		}
		
		
		/* paroller 
		---------------------------------------------------------------------*/
		if($("[data-paroller-factor]").length){
			$("[data-paroller-factor]").paroller({
				direction: 'vertical',
				type: 'foreground',
			});
		}

		// Animate Reveal


		/* newsletter 
		---------------------------------------------------------------------*/
			
			$("#newsletter .bg-gray, #newsletter .col-sm-5").click(function () {
				$(".newsletter-text .wpcf7-email").addClass('newsletterfocus').focus();
				return false;
			});
			$('.newsletter-text .wpcf7-email').on('click tap', function(event) {
				event.stopPropagation();
			});
			$(document).on('click tap', function(e) {			
				if( $(e.target).parent('#newsletter .bg-gray').length === 0 ) {
					$('.newsletter-text .wpcf7-email').removeClass('newsletterfocus')
				}
			});

		/* reveal
		---------------------------------------------------------------------*/
		var reveals = $('.reveal');
		
			if (reveals.length > 0) {

			for (var i = reveals.length - 1; i >= 0; i--) {

				var revealContainer = reveals[i];
				//var revealItems = revealContainer.querySelectorAll('.rev_item');

				/*TweenMax.to(revealItems, .5, {
					alpha: 0
				});*/

				// Animate reveal on class change
				var observer = new MutationObserver(function(revealContainer) {
						animateReveal(revealContainer);
					})
					// Class change event listener
				observer.observe(revealContainer, {
					attributes: true,
					attributeFilter: ['class'],
					childList: false,
					characterData: false
				})

			};

		}
		
		window.addEventListener('scroll', function() {
			if (reveals) {
				qReveal(reveals)
			};
		});
		
		
		
		/* Cookies close
		---------------------------------------------------------------------*/
		$(document).on('click','.scroll-arrow img', function(){
			var getoofset = $('.featured-projects-row').offset().top;
			$('html:not(:animated),body:not(:animated)').animate({scrollTop:getoofset},'noraml');
			return false;
		});
		
		
		$('.subnav-row ul li').on('touchstart', function(e){
				fire(e);
		});

		/* reveal First
		---------------------------------------------------------------------*/
		
			//alert('sd');
			$('.reveal:first').addClass('q_active');
			/*$('html,body').animate({
				scrollTop:1
			},250);*/
		
		
		
		
		/* project Details slider
		---------------------------------------------------------------------*/
		if($('.project-details-slider').length && $('.project-details-slider .swiper-slide').length > 1 ) {
				
			var pdautoplay = 50;
			var pdtime = 1;
			var $pdbar,
				pdisPause,
				pdtick,
				pdpercentTime;
			var prodetailsswiper = new Swiper('.project-details-slider', {
				direction: 'horizontal',
				spaceBetween: 0,
				loop:true,
				//watchSlidesProgress: true,
				dynamicBullets: true,
				//watchOverflow: true,
				//parallax: true,
				speed: 600,
				slidesPerView: 1,
				allowTouchMove: true,
				pagination: {
					el: '.project-details-nav',
					clickable: true,
					renderBullet: function (index, className) {
					  return '<span class="' + className + '">' + (index + 1) + '</span>';
					},
				},
				navigation: {
					nextEl: '.project-nav-row .swiper-button-next',
					prevEl: '.project-nav-row .swiper-button-prev',
				},
				on: {
					init: function () {
						//animationStart();
					},
					transitionStart: function () {
						//animationStart();
						pdstartProgressbar();
					},
					progress: function () {
						//move();
					},	
				},
			});
			
			$pdbar = $('.project-details-slider-box .slide-timer span');
			function pdstartProgressbar() {
				pdresetProgressbar();
				pdpercentTime = 0;
				pdisPause = false;
				pdtick = setInterval(pdinterval, 50);
			}
			function pdinterval() {
				if (pdisPause === false) {
					pdpercentTime += 1 / (pdtime + 0.1);
					$pdbar.css({
						width: pdpercentTime + "%"
					});
					if (pdpercentTime >= 100) {
						prodetailsswiper.slideNext();
						pdstartProgressbar();
					}
				}
			}
			function pdresetProgressbar() {
				$('.project-details-slider-box .slide-timer span').css({
					width: 0 + '%'
				});
				clearTimeout(pdtick);
			}
		} else {
			$('.project-details-slider').parents('.project-details-slider-box').find('.slider-navblock, .project-nav-row').hide();
		}
		
		/* Header Sticky
		* ----------------------------------------------------------------------------------------------------------------------*/
		var intialtop = $(document).scrollTop();
		var headerheight = $("#header").outerHeight();
		$(window).scroll(function() {
			var afterscrolltop = $(document).scrollTop();
			if( afterscrolltop > 1 ) {
				$("#header").addClass("header-fix");
			} else {
				$("#header").removeClass("header-fix");
			}
			intialtop = $(document).scrollTop();
		});
		
		var lastScrollTop = 0;
		$(window).scroll(function(event){
			var st = $(this).scrollTop();
			if (st > lastScrollTop){
			  $("#header").removeClass("slide-in");
			} else {
			  $("#header").addClass("slide-in");
			}
			lastScrollTop = st;
		});
		
		/* page loader
		* ----------------------------------------------------------------------------------------------------------------------*/
		if($('.intro').length){
			var loadBar = document.querySelector('.intro-bar');
			var progress = 0;
			var pageStatus = null;
			var loadBarInterval = 33;
			var tlset = new TimelineMax();
			setInterval(function() {
				if (progress < 99) {
					progress++;
					tlset.to(loadBar, 0.1, {
						scaleX: progress / 100
					});
					if (progress > 30 && progress <= 60) {
						loadBarInterval = 45;
					} else if (progress > 60 && progress <= 90) {
						loadBarInterval = 52;
					} else if (progress > 90) {
						loadBarInterval = 59;
					}
				}
			}, loadBarInterval);
			document.addEventListener('readystatechange', function(e) {
				//alert('load');
				pageStatus = document.readyState;
				if (pageStatus === 'complete') {
					//alert('sasd');
					progress = 100;
					var tl = new TimelineMax({
						onComplete: function onComplete() {
							var intro = document.querySelector(".intro");
							intro.parentNode.removeChild(intro);
						}
					});
					tl.add("start").to(loadBar, 0.45, {
						scaleX: 1,
						ease: Power2.easeOut
					}, "start").add("circles").to([loadBar, ".intro figure"], 1, {
						opacity: 0,
						left:'100px',
					}, "circles").staggerTo(".is2", 1.5, {
						width: '0',
						right:'0',
						ease: Expo.easeInOut
					}, 0.195, "circles").staggerTo(".is1", 1.9, {
						width: '0',
						right:'0',
						ease: Expo.easeInOut
					}, 0.245, "circles");
				}
			});
		}
		/* Cookies close
		---------------------------------------------------------------------*/
		$('.floor-table a:nth-child(11)').nextAll().hide();
		$(document).on('click','.floor-plan-box .link-center a', function(){
			$('.floor-table a:nth-child(10)').nextAll().show();
			$('.floor-table').addClass('showall');
			$(this).hide();
			return false;
		});
		
		/* 404 page
		---------------------------------------------------------------------*/
		var pageX = $(document).width();
		var pageY = $(document).height();
		var mouseY=0;
		var mouseX=0;

		$(document).mousemove(function( event ) {
			//if($('body').hasClass())
			mouseY = event.pageY;
			yAxis = (pageY/2-mouseY)/pageY*300; 
			//horizontalAxis
			mouseX = event.pageX / -pageX;
			xAxis = -mouseX * 100 - 100;

			$('.box__ghost-eyes').css({ 'transform': 'translate('+ xAxis +'%,-'+ yAxis +'%)' }); 

			//console.log('X: ' + xAxis);

		});
		
		/* 404 page
		---------------------------------------------------------------------*/
		
		if($(".video-intro-text").length){
			var line = $('.cd-words-wrapper b');
			/*var tl = new TimelineLite({
						onComplete: function(){
							tl.restart();
						}
					});
			TweenLite.defaultEase = Expo.easeInOut;*/
			var tl = new TimelineMax({repeat:-1});
			TimelineMax.defaultEase = Power4.easeInOut;
			var time = 0.2;
			var x = 35;

			tl.add ( TweenMax.staggerFromTo (
					line, time,
						{
							opacity: 0,
							x:x,
						},
						{	
							opacity: 1,
							x: 0,
						},
					3.1 ))
				.add ( TweenMax.staggerTo (
					line, time,
						{
							delay: 2.85, 
							opacity: 0,
							x: -x,
						},
					3.1 ), '+0.2') 
		}
		
		
		$(".contact-form-box .form-group input.wpcf7-form-control").on('focus', function(){
			$(this).parents('.form-group').addClass('hasfocused');
		}).on('focusout', function(){
			$(this).parents('.form-group').removeClass('hasfocused');
		});
		
		//Ipad Touch fire
		$('.navbar li').on('touchstart', function(e){
			if (screencheck(1023)) {
				fire(e);
			}
		});
		
		if (window.history && window.history.pushState && $('body').hasClass('home') ) {
			window.history.pushState('', null, './');
			$(window).on('popstate', function() {
				if($('body').hasClass('overflowhidden')){
					window.history.pushState(null, "", window.location.href);
					$('.popouterbox .close-dialogbox').trigger('click');
				}
			});
		}


		//OS class
		if (navigator.userAgent.indexOf('Mac') > 0) {
			$("html").addClass("mac");
		} else {
			$("html").addClass("windows");
		}
		
		
/*--------------------------------------------------------------------------------------------------------------------------------------*/		
	});	

/*All function nned to define here for use strict mode
----------------------------------------------------------------------------------------------------------------------------------------*/

function resizeImg(reveals) {
	for (var i = reveals.length - 1; i >= 0; i--) {

        var revealContainer = reveals[i];
        var clipReveal = revealContainer.querySelectorAll('.rev_clip');

        if (clipReveal.length > 0) {
            var w = clipReveal[0].clientWidth;

            var img = clipReveal[0].querySelector('.clip_image');
            var wrap = clipReveal[0].parentNode;
            var h = img.clientHeight;

            if (h != 0) {
                wrap.style.height = h + 'px';
                img.style.height = h * 1.1 + 'px';
                img.style.width = 'auto';
            }
        };
    }
}
	
function animateReveal(e) {
    var container = e[0].target;
    //var revealItems = container.querySelectorAll('.rev_item');

    // image clipping mask reveal
    var clipReveal = container.querySelectorAll('.rev_clip');

    if (clipReveal.length > 0) {
        var w = clipReveal[0].clientWidth;
        if (container.classList.contains("q_active")) {
            TweenMax.fromTo(clipReveal, 0.6, {
                x: 0,
                alpha: 1,
                clipPath: 'inset(0px ' + w + 'px 0px 0px)',
                webkitClipPath: 'inset(0px ' + w + 'px 0px 0px)'
            }, {
                x: 0,
                alpha: 1,
                clipPath: 'inset(0px 0px 0px 0px)',
                webkitClipPath: 'inset(0px 0px 0px 0px)',
                ease: Expo.easeInOut
            })
        } else {
            TweenMax.to(clipReveal, 0.6, {
                alpha: 0,
                x: -10,
                ease: Power4.easeInOut
            })
        }
    }

}

function qReveal(reveals) {
    var scrollPos = window.scrollY;
	for (var i = reveals.length - 1; i >= 0; i--) {
        var revealContainer = reveals[i];
        revealHandler(revealContainer, scrollPos);
    }
	//console.log(revealContainer.offsetTop);
    function revealHandler(revealContainer, scrollPos) {
		var currentoffset = jQuery(revealContainer).offset().top;
        var elemTop = currentoffset - (window.innerHeight / 1.5);
		
		
        var elemBtm = elemTop + (revealContainer.clientHeight * 1.1);
        var revealItems = revealContainer.querySelectorAll('.rev_item');
        if (scrollPos > elemTop) {
            if (revealContainer.classList.contains("q_active")) return;
            revealContainer.classList.add("q_active");
        } 
		/*else {
            if (!revealContainer.classList.contains("q_active")) return;
            revealContainer.classList.remove("q_active");
        }*/
    }
}

	// if($('#contact-map').length){
	// 	var styles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},
	// 	{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},
	// 	{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},
	// 	{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},
	// 	{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},
	// 	{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},
	// 	{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},
	// 	{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},
	// 	{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},
	// 	{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},
	// 	{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},
	// 	{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},
	// 	{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},
	// 	{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
	// 		var	map;
	// 		var	mapOptions = {
	// 			center: new google.maps.LatLng(47.263306740400594, 11.373521897347162),
	// 			zoom:17,
	// 			scrollwheel: false,
	// 			panControl: false,
	// 			mapTypeControl:false,
	// 			streetViewControl: false,
	// 			disableDefaultUI: false,
	// 			zoomControl: true,
	// 			styles: styles
	// 		};
	// 		function initMap() {
	// 			var map = new google.maps.Map(document.getElementById("contact-map"), mapOptions);
	// 			var bounds = new google.maps.LatLngBounds();
	// 			var myIcon = new google.maps.MarkerImage("img/marker-pin.png", null, null, null, new google.maps.Size(44,58));
	// 			var marker = new google.maps.Marker({
	// 				position: new google.maps.LatLng(47.263306740400594, 11.373521897347162),
	// 				map: map,
	// 				title: 'Safarihub',
	// 				icon: myIcon
	// 			});
	// 		}
	// 	initMap();
	// 	google.maps.event.addDomListener(window, 'load', initMap);
	// 	//google.maps.event.addDomListener(window, 'resize', initMap);
	// }

	// Multiple Map locations for about page
	// var multipleMaps = function(mapID, mapLat, mapLng) {

	// 	//custom styles
	// 	var abstyles = [{"featureType":"all","elementType":"all","stylers":[{"visibility":"simplified"},{"gamma":"0.00"},{"saturation":"-100"},{"lightness":"62"}]},{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#fcfcfc"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#fcfcfc"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]}];

	// 	var	map;
	// 	var mapOptions = {
	// 	//center: google.maps.Map.LatLng(mapLat, mapLng),
	// 		center: { lat: mapLat, lng: mapLng },
	// 		zoom: 12,
	// 		scrollwheel: false,
	// 		panControl: false,
	// 		mapTypeControl:false,
	// 		streetViewControl: false,
	// 		disableDefaultUI: false,
	// 		zoomControl: false,
	// 		styles: abstyles
	// 	};

	// 	var abinitMap = function() {
	// 		map = new google.maps.Map(document.getElementById(mapID), mapOptions);
	// 		const bounds = new google.maps.LatLngBounds();
	// 		const myIcon = new google.maps.MarkerImage('images/marker-pin.png', null, null, null, new google.maps.Size(44,58));
	// 		const marker = new google.maps.Marker({
	// 			position: new google.maps.LatLng(mapLat, mapLng),
	// 			map: map,
	// 			title: 'Location',
	// 			icon: myIcon
	// 		});
	// 	}
	// 	abinitMap();

	// };

	

	// setTimeout( () => {
	// 	const abMapItems = document.querySelectorAll('.contact-map-box');
	// 	abMapItems.forEach( (item) => {
	// 		multipleMaps( item.getAttribute('id'), eval( item.getAttribute('data-lat') ), eval( item.getAttribute('data-lng') ) );
	// 	})
	// }, 100);



	// Multiple Map locations for about page
	if($('.contact-map-box').length) {
		var multipleMaps = function(mapID, mapLat, mapLng) {

			//custom styles
			var abstyles = [
				{
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#f5f5f5"
					}
				  ]
				},
				{
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#f5f5f5"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#bdbdbd"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#eeeeee"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "poi.business",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#e5e5e5"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#9e9e9e"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#ffffff"
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#dadada"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#9e9e9e"
					}
				  ]
				},
				{
				  "featureType": "transit.line",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#e5e5e5"
					}
				  ]
				},
				{
				  "featureType": "transit.station",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#eeeeee"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#c9c9c9"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#9e9e9e"
					}
				  ]
				}
			  ];

			var	map;
			var mapOptions = {
			//center: google.maps.Map.LatLng(mapLat, mapLng),
				center: { lat: mapLat, lng: mapLng },
				zoom: 12,
				scrollwheel: false,
				panControl: false,
				mapTypeControl:false,
				streetViewControl: false,
				disableDefaultUI: false,
				zoomControl: false,
				styles: abstyles
			};

			var abinitMap = function() {
				map = new google.maps.Map(document.getElementById(mapID), mapOptions);
				var bounds = new google.maps.LatLngBounds();
				// Dynamically add markers for local
				// var myIcon = new google.maps.MarkerImage('images/marker-pin.png', null, null, null, new google.maps.Size(44,58));
				// Dynamically add markers for wordpress version
				var myIcon = new google.maps.MarkerImage(Bauwerk_Genrs.imgurl+"/marker-pin.png", null, null, null, new google.maps.Size(37,47));
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(mapLat, mapLng),
					map: map,
					title: 'Location',
					icon: myIcon
				});
			}

			abinitMap();

		}
		setTimeout( function() {
			var abMapItems = document.querySelectorAll('.contact-map-box');
			abMapItems.forEach( function(item) {
				multipleMaps( item.getAttribute('id'), eval( item.getAttribute('data-lat') ), eval( item.getAttribute('data-lng') ) );
			})
		}, 100);

	}
	
	if($(".youtubeVideo").length){
		var youtube = $(".youtubeVideo")[0];
		let youtubeURLCode = new URL(youtube.dataset.iframeUrl).searchParams.get("v")
	
		var source = "https://img.youtube.com/vi/"+ youtubeURLCode +"/sddefault.jpg";
		var image = new Image();
		image.src = source;
		image.alt = "Youtube Poster Image";
		image.addEventListener( "load", function() {
			youtube.appendChild( image );
		});
	
		youtube.addEventListener( "click", function() {
			var iframe = document.createElement( "iframe" );
			iframe.setAttribute( "frameborder", "0" );
			iframe.setAttribute( "allowfullscreen", "" );
			this.classList.add("hide-play-btn");
			iframe.setAttribute( "src", `https://www.youtube.com/embed/${youtubeURLCode}?rel=0&autoplay=1&mute=1`);
			this.innerHTML = "";
			this.appendChild(iframe);
			setTimeout(()=>{
				console.log( iframe.src)
			},500)
			
		} );
	}


/*--------------------------------------------------------------------------------------------------------------------------------------*/
})(jQuery, window, document);